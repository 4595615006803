@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "CocoBubble";
  src: url(fonts/cocobubble.ttf) format("truetype");
}

@font-face {
  font-family: "BubblegumSans";
  src: url(fonts/BubblegumSans-Regular.ttf) format(truetype);
}

@font-face {
  font-family: "RaceSport";
  src: url(fonts/racesport.ttf) format("truetype");
}

@font-face {
  font-family: "JetbrainsMono";
  src: url(fonts/JetBrainsMono-VariableFont_wght.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Coco Bubble", "BubblegumSans", source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

