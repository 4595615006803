.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  background-color: #0F0D2E; /* deep red */
  color: #fff;
}

.App-header {
  /* Remove the old color */
  background-color: transparent;
  /* Some padding so your content isn't flush against the edges */
  padding: 2rem;
}

/* Example diagonal shape section */
.diagonal-section {
  position: relative;
  background: #400000; /* darker red for contrast */
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  min-height: 50vh;
  margin-top: 2rem;
}

/* If you want a red border on that diagonal */
.diagonal-section::before {
  content: "";
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  border: 2px solid #e10000; /* bright red */
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  pointer-events: none; /* so it doesn't interfere with content */
}
